<template>
  <div class="inner-pages hd-white" style="background-color: #f7f7f7">
    <div id="wrapper">
      <div class="inner-pages homepage-4 agents list hp-6 full hd-white">
        <section class="properties-list full featured portfolio blog pb-0">
          <div class="container">
            <div v-if="property.images" class="col-md-12 px-0">
              <simple-list-property :enquireMargin="true" :property="property">
                <template #fullListing>
                  <p class="d-none"></p>
                </template>
                <template #fullListingAlt>
                  <p class="d-none"></p>
                </template>
                <template #descriptionTitle="{ property }">
                  {{
                    property.description
                      ? property.description[0]
                        ? `${sliceString(
                            property.description[0].value[
                              Object.keys(property.description[0].value)[0]
                            ]
                          )}`
                        : ""
                      : ""
                  }}<button
                    class="button-link"
                    @click="scrollToMain"
                    v-if="
                      (property.description
                        ? property.description[0]
                          ? property.description[0].value[
                              Object.keys(property.description[0].value)[0]
                            ]
                          : ''
                        : ''
                      ).length > 280
                    "
                  >
                    ...more
                  </button>
                </template>
              </simple-list-property>
            </div>
          </div>
        </section>
      </div>
      <section
        style="padding-top: 0px !important; padding-bottom: 0px !important"
        class="single-proper blog details"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 blog-pots">
              <div
                style="border-radius: 8px 8px 8px 8px; justify-content: center;"
                class="homes-content details row m-0 mb-30"
              >
                <div v-if="property.propertyType !== 'none'" class="col-md-2 res-magin-bottom text-center">
                  <img
                    class="custome-images-icon res-sm-img"
                    :src="require('../assets/images/icons/Home-Icon.webp')"
                  />
                  <p style="font-weight: bold" class="m-0">Property Type:</p>
                  <span>{{ capitalize(property.propertyType) }} </span>
                </div>
                <div class="col-md-2 res-magin-bottom text-center">
                  <img
                    class="custome-images-icon res-sm-img"
                    :src="require('../assets/images/icons/Tenure-Icon.webp')"
                  />
                  <p style="font-weight: bold" class="m-0">Tenure:</p>
                  <span>
                    {{ capitalize(property.tenure) }}hold</span
                  >
                </div>
                <div v-if="property.garage  && property.garage != 'none'" class="col-md-2 res-magin-bottom text-center">
                  <img
                    class="custome-images-icon res-sm-img"
                    :src="require('../assets/images/icons/Garage-Icon.webp')"
                  />
                  <p style="font-weight: bold" class="m-0">Garage:</p>
                  <span>{{ capitalize(property.garage) }}</span>
                </div>
                <div v-if="property.parking  && property.parking != 'none'" class="col-md-2 res-magin-bottom text-center">
                  <img
                    class="custome-images-icon res-sm-img"
                    :src="require('../assets/images/icons/Parking-Icon.webp')"
                  />
                  <p style="font-weight: bold" class="m-0">Parking:</p>
                  <span
                    >{{ capitalize(enums[property.parking]) }}
                    {{ property.noOfCars }}
                    {{ `car${property.noOfCars == 1 ? "" : "s"}` }}</span
                  >
                </div>
                <div v-if="property.garden" class="col-md-2 res-magin-bottom text-center">
                  <img
                    class="custome-images-icon res-sm-img"
                    :src="require('../assets/images/icons/Garden-Icon.webp')"
                  />
                  <p style="font-weight: bold" class="m-0">Garden:</p>
                  <span v-if="property.garden != 'No'"
                    >{{ capitalize(property.garden) }}
                    {{
                      property.gardenArea == "acres" ? "Acres" : "Sq. Meters"
                    }}</span
                  >
                  <span v-else>None</span>
                </div>
                <div v-if="property.swimmingPool  && property.swimmingPool != 'none'" class="col-md-2 res-magin-bottom text-center">
                  <img
                    class="custome-images-icon res-sm-img"
                    :src="
                      require('../assets/images/icons/Swimming-Pool-Icon.webp')
                    "
                  />
                  <p style="font-weight: bold" class="m-0">Swimming Pool:</p>
                  <span v-if="property.swimmingPool != 'No'">{{
                    capitalize(property.swimmingPool)
                  }}</span>
                  <span v-else>None</span>
                </div>
              </div>
              <div v-if="checkIfEpcOrFloorPlan()">
                <div
                  v-if="
                    property
                      ? property.floorPlan.length
                      : null ||
                        (property
                          ? property.epcCertificate
                            ? property.epcCertificate.epcDocument.length
                            : false
                          : null)
                  "
                  style="border-radius: 8px 8px 8px 8px"
                  class="floor-plan property wprt-image-video w50 pro"
                >
                  <div v-if="property.floorPlan.length" class="row">
                    <div
                      class="col-md-4 mb-5 res-height-flooor"
                      v-for="(image, index) in JSON.parse(property.floorPlan)"
                      :key="index"
                      style="height: 300px; width: 319px"
                    >
                      <img
                        @click="enlargeImage('floorPlan', image)"
                        style="width: 100%; height: 100%; object-fit: fill"
                        class="mb-3"
                        alt="image"
                        :src="`${image}`"
                      />
                    </div>
                    <div
                      style="height: 300px; width: 319px"
                      class="col-md-4 mb-5  res-height-flooor "
                      v-if="property.epcCertificate"
                    >
                      <img
                        @click="enlargeImage('epcCertificate')"
                         
                        v-if="
                          property.epcCertificate.epcDocument.length &&
                          getExtension(
                            property.epcCertificate.epcDocument[0]
                          ).includes('png')
                        "
                        style="width: 100%; height: 100%; object-fit: fill"
                        alt="image"
                        :src="
                          property.epcCertificate
                            ? `${property.epcCertificate.epcDocument}`
                            : ''
                        "
                      />
                      <a
                        v-else-if="
                          getExtension(
                            property.epcCertificate.epcDocument[0]
                          ).includes('pdf')
                        "
                        target="_blank"
                        :href="`${property.epcCertificate.epcDocument[0]}`"
                        >{{ property.epcCertificate.epcDocument[0] }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                 <div class="col-lg-12 col-md-12 blog-pots">
              <div
                style="border-radius: 8px 8px 8px 8px"
                class="single homes-content details mb-30"
              >
                <h3>
                  {{ property.street }}, {{ property.city }},
                  {{ property.postCode }}
                </h3>
                <div class="d-flex justify-content-center">
                  <GmapMap
                    :center="{ lat: lat, lng: lng }"
                    :zoom="15"
                    map-type-id="terrain"
                    style="width: 100%; height: 500px"
                  >
                  <GmapMarker
    :position="{ lat: lat, lng: lng }"
  />
                  </GmapMap>
                </div>
              </div>
            </div>
                <div class="col-md-12">
                  <div
                    style="border-radius: 8px 8px 8px 8px"
                    class="blog-info details mb-30"
                  >
                    <h5 class="mb-4 main-description">About Property</h5>
                    <div class="mb-4">
                      {{
                        property.description
                          ? property.description[0]
                            ? property.description[0].value[
                                Object.keys(property.description[0].value)[0]
                              ]
                            : ""
                          : ""
                      }}
                    </div>
                    <h5 class="mb-4">Description</h5>
                    <span
                      class="mb-3"
                      v-for="(description, index) in property.description"
                      :key="index"
                    >
                      <div
                        class="mb-4"
                        v-if="
                          description.value[
                            Object.keys(description.value)[0]
                          ] &&
                          description.removed !== 'true' &&
                          Object.keys(description.value)[0] !==
                            `mainDescription` &&
                          (Object.keys(description.value)[0] ==
                          `tenureDescription`
                            ? property.tenure == 'lease'
                            : true)
                        "
                      >
                        <h3>{{ Object.keys(description.value)[0] }}:</h3>
                        <p style="white-space: break-spaces;">
                          {{
                            description.value[Object.keys(description.value)[0]]
                          }}
                        </p>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <section
        style="padding-top: 0px !important"
        class="single-proper blog details"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 blog-pots">
              <div
                style="border-radius: 8px 8px 8px 8px"
                class="single homes-content details mb-30"
              >
                <h3>
                  {{ property.street }}, {{ property.city }},
                  {{ property.postCode }}
                </h3>
                <div class="d-flex justify-content-center">
                  <GmapMap
                    :center="{ lat: lat, lng: lng }"
                    :zoom="15"
                    map-type-id="terrain"
                    style="width: 100%; height: 500px"
                  >
                  <GmapMarker
    :position="{ lat: lat, lng: lng }"
  />
                  </GmapMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </div>
    <b-modal
      size="xl"
      centered
      hide-header
      hide-footer
      ref="imageBox"
      id="modal-1"
    >
      <div
        class="d-flex justify-content-center p--relative"
        v-if="property.floorPlan && shouldShow == 'floorPlan'"
        :style="`height: ${screenHeight / (screenHeight >= 400 ? 1.2 : 1)}px; width: ${
              screenWidth / (screenWidth >= 400 ? 2.4 : 1)
            }px; background-color: ${screenWidth >= 400 ? 'grey' : '#686262'}`"
      >
      <i @click="$refs[`imageBox`].hide()"  class="fa fa-times hover fatimes " aria-hidden="true"></i>
      <div class="d-flex align-items-center p-realtive">
        <img class="mb-3" alt="image" :src="`${currentImageUrl}`" />
      </div>
      </div>
      <div
        class="d-flex justify-content-center p--relative"
        v-if="property.epcCertificate && shouldShow == 'epcCertificate'"
        :style="`height: ${screenHeight / (screenHeight >= 400 ? 2 : 1)}px; width: ${
              screenWidth / (screenWidth >= 400 ? 2.4 : 1)
            }px; background-color: ${screenWidth >= 400 ? 'grey' : '#686262'}`"
      >
 <i @click="$refs[`imageBox`].hide()"  class="fa fa-times hover fatimes " aria-hidden="true"></i>
  <div class="d-flex align-items-center ">
       <img style="height:100%; width:100%"
          alt="image"
          :src="
            property.epcCertificate
              ? `${property.epcCertificate.epcDocument}`
              : ''
          "
        />
      </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
const simpleListProperty = () =>
  import(/* webpackChunkName: "dNs2Jc3oCH" */ "../components/simple-list-property");
import { mapGetters } from "vuex";
import _ from "lodash";
import { capitalize } from "../utils";
import { gmapApi } from "vue2-google-maps";

export default {
  data() {
    return {
      lat: 0,
      lng: 0,
      currentImageUrl: "",
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      property: {},
      shouldShow: "",
      enums: {
        onStreet: "On Street",
        offStreet: "Off Street",
        permitted: "Permitted",
        allocated: "Allocated",
      },
    };
  },
  watch: {
    google(){
      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ address: this.formattedPostCode(this.property.postCode) },  (results, status) => {
        this.lat = results[0].geometry.location.lat()
        this.lng = results[0].geometry.location.lng()
      });
    },
    property() {
      if(!this.google)
        return;
      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ address: this.formattedPostCode(this.property.postCode) },  (results, status) => {
        this.lat = results[0].geometry.location.lat()
        this.lng = results[0].geometry.location.lng()
      });
    },
  },
  computed: {
    google: gmapApi,
    ...mapGetters({
      properties: "getProperties",
      userProperties: "getUserProperties",
    }),
    getThreeRecentProperties() {
      return this.properties.properties
        ? this.properties.properties.slice(0, 3)
        : [];
    },
  },
  components: {
    simpleListProperty,
  },
  methods: {
    capitalize: capitalize,
    formattedPostCode(postCode) {
      let formattedPostCode = "";
      let arr = [];
      if (postCode.length == 5) {
        arr[0] = postCode.substr(0, 2);
        arr[1] = " ";
        arr[2] = postCode.substr(2);
        formattedPostCode = arr[0] + arr[1] + arr[2];
      } else if (postCode.length == 6) {
        arr[0] = postCode.substr(0, 3);
        arr[1] = " ";
        arr[2] = postCode.substr(3);
        formattedPostCode = arr[0] + arr[1] + arr[2];
      } else if (postCode.length == 7) {
        arr[0] = postCode.substr(0, 4);
        arr[1] = " ";
        arr[2] = postCode.substr(4);
        formattedPostCode = arr[0] + arr[1] + arr[2];
      } else formattedPostCode = postCode;
      return formattedPostCode;
    },
    checkIfEpcOrFloorPlan() {
      if (Object.keys(this.property).length) {
        if (
          JSON.parse(this.property.floorPlan).length ||
          this.property.epcCertificate.epcDocument.length
        )
          return true;
        return false;
      }
      return false;
    },
    getExtension(str) {
      var re = /(?:\.([^.]+))?$/;
      return re.exec(str);
    },
    enlargeImage(comp, image = "") {
      this.currentImageUrl = image;
      this.shouldShow = comp;
      this.$refs.imageBox.show();
    },
    sliceString(str) {
      if (str) {
        if (str.length > 280) {
          this.shouldShow = true;
          return str.slice(0, 280);
        } else return str;
      } else return "";
    },
    transformDescription(name) {
      if (name.includes("bedroom"))
        return `Bedroom ${name.split("bedroom")[1]}`;
      else if (name.includes("bathroom"))
        return `Bathroom ${name.split("bathroom")[1]}`;
      else if (name.includes("propertyType")) return `Property Type`;
      else if (name.includes("tenure")) return `Tenure`;
      else if (name.includes("mainPageDescription")) return `Main Description`;
      else if (name.includes("garage")) return `Garage`;
      else if (name.includes("parking")) return `Parking`;
      else if (name.includes("garden")) return `Garden`;
      else if (name.includes("swimmingPool")) return `Swimming Pool`;
      else {
        let index = "";
        for (let i = 0; i <= name.length - 1; i++) {
          if (parseInt(name[i])) {
            index = i;
          } else {
            index = i + 1;
          }
        }
        return name.substr(0, index).trim();
      }
    },
    scrollToMain() {
      document
        .querySelector(".main-description")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
  async mounted() {
    setTimeout(() => {
      if (Object.keys(this.$route.query).includes("read-more")) {
        this.scrollToMain();
      }
    }, 100);
    if (_.isEmpty(this.properties)) {
      await this.$store.dispatch("getAllProperties", {
        currentPage: 1,
        perPage: 10,
        sortItem: "id",
        sortOrder: "desc",
        search: "",
      });
    }
    if (_.isEmpty(this.userProperties)) {
      await this.$store.dispatch("getUserProperties");
    }
    this.property = _.find(this.properties.properties, (property) => {
      return property.id == this.$route.params.id;
    });
    if (!this.property) {
      this.property = _.find(this.userProperties, (property) => {
        return property.id == this.$route.params.id;
      });
    }
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

.button-link {
  background: transparent;
  border: none;
  color: blue;
}

.p--relative{
  position: relative;
}

.w-30{
  width: 30px;
  height:30px;
}
.p-realtive{
  position: relative;
}

.fatimes{
  position: absolute;
    z-index: 90;
    
       left: 95%;
    top: 1%;
    font-size: 21px;
}

@media (min-width: 300px) and (max-width: 700px) {
  .res-sm-img {
    width: 22px !important;
    height: 22px !important;
  }

  .res-hide-mobile{
    display: none !important;
  }
.res-height-flooor{
  height: 160px !important;
    width: 160px !important;
}
  .res-magin-bottom{
    margin-bottom: 1rem!important;
  }

  .res-display-sm-hide {
    display: none !important;
  }

.res--top{
  left: 90.5% !important;
    top: 10px !important;
}
   .fatimes{
    left: 89.5% !important;
    top: 19px !important;
  }
}

@media (min-width: 700px) and (max-width: 4146px) {
  .span-inline-box {
    width: 300px !important;
  }

  .res-show-mobile{
    display: none !important;
  }
  .res-disply-big-hide {
    display: none !important;
  }
}
.transparent >>> .modal-content {
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.project-single >>> .flipster {
  width: 100%;
}
.project-single >>> img {
  width: 250px;
  height: 255px;
}
div >>> .marked-cell {
  background-color: yellow;
}

div >>> #modal-1___BV_modal_content_ {
  background-color: rgba(255, 255, 255, 0);
  border: none;
}

i.hover:hover {
  color: red !important;
}

div >>> #modal-1___BV_modal_body_ {
  display: flex;
  justify-content: center;
}
</style>

